import React,{useState,useRef,useCallback,useEffect, memo} from 'react';
import WithToast from '../api/context_api_functions/withtoast';
import Select, { components } from 'react-select';
import { getMaskedEmail, handleApiError, process_params } from '../api/commonfunctions/common';
import axiosInstance from '../api/axiosinstance';
import eyeslash from '../images/eye_slash-01.png';
import eye from '../images/eye-01.png';
import eyegrey from '../images/eye-01pass.png';
import Modal from 'react-bootstrap/Modal';
import eyeslashgrey from '../images/eye-slash-01passw.png';
import { country_codeapi, countryselectapi, signupapi, signupverifyapi } from '../api/url';
import { useIpContext } from '../api/context_api_functions/ipcontext';
const mcclistapiurl = country_codeapi();
const countryselect = countryselectapi();
const signupapiurl = signupapi();
const signupverifyapiurl = signupverifyapi();
const Signup = memo(({showErrorToast,signupclick,setIsLoading4,myaccountdomainurl})=>{
  const [inlineerror,setInlineError] = useState({
    nameerror : '',
    passwordcurrenterror : '',
    emailerror : '',
  });
  const [enabledverify,setEnabledverify] = useState(false);
  const [enabledsignup,setEnabledsignup] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const mobileInputRef = useRef(null);
  const { ipCountryCode, getUserIpCountryCode } = useIpContext();
  const [countdown, setCountdown] = useState(120);
  const [selwidth, setSelwidth] = useState(0);
  const [showPasswordnew , setShowPasswordnew] = useState(false);
  const [otp, setOtp] = useState('');
  const [show1,setShow1] = useState(false);
  const [showPasswordcur , setShowPasswordcur] = useState(false);
  const [formValues,setFormValues] = useState({
    name:'',email:'',mobile:'',mcc_id:'',country:null,countryloading:false,countryoption:[],countryOptions:[],isLoading3:false,dial_code:'',country_code:'',passwordnew:'',passwordcurrent:''
  })
  const [error422,setError422] = useState('');
  const updateExampleNumber = useCallback((iso2) => {
    if (window.intlTelInputUtils) {
      try {
        const exampleNumber = window.intlTelInputUtils.getExampleNumber(
          iso2,
          true,
          window.intlTelInputUtils.numberFormat.E164
        );
        const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
        const maxLength = cleanedNumber.length;
        return maxLength;
      } catch (error) {
        console.error('Error fetching example number for country code:', iso2, error);
        return 15; // Fallback to a default length
      }
    } else {
      console.error('intlTelInputUtils is not loaded');
      return 15; // Fallback to a default length
    }
  }, []);
  const fetchCountryCode = useCallback(async () => {
    if (ipCountryCode){ 
    if (formValues.countryOptions.length > 0) return;
    setFormValues((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
  
    try {
      const response = await axiosInstance.get(mcclistapiurl);
      const data = response.data;
  
      if (data.message === 'Success') {
        const mccList = data.data;
        const codes = mccList.map((mcc) => ({
          country_code: mcc.country_code,
          dial_code: mcc.code,
          mcc_id: mcc.id,
          label: `${mcc.name} (+${mcc.code})`,
          value: mcc.country_code,
          flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
        }));
  
        setFormValues((prevState) => ({
          ...prevState,
          countryOptions: codes,
        }));
        let code = ipCountryCode;
        const selectedCountryData = codes.find(
          (country) => country.country_code === code
        );
  
        if (selectedCountryData) {
          setFormValues((prevState) => ({
            ...prevState,
            mcc_id: selectedCountryData.mcc_id,
            dial_code: `+${selectedCountryData.dial_code}`,
            country_code: selectedCountryData.country_code,
          }));
  
          const maxLength =  updateExampleNumber(selectedCountryData.country_code.toLowerCase());
          if (maxLength !== null && mobileInputRef.current) {
            mobileInputRef.current.setAttribute('maxlength', maxLength);
          }
        }
      } else {
        console.error('Failed to fetch country codes');
      }
    } catch (error) {
      handleApiError(error, null, null, true, showErrorToast);
    } finally {
      setFormValues((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }

}, [formValues.countryOptions.length, updateExampleNumber, showErrorToast,ipCountryCode]);
const countryList = useCallback(async () => {
  if(formValues.countryoption.length > 0) return;
    try {
      setFormValues(prev => ({ ...prev, countryLoading: true }));
      const response = await axiosInstance.get(countryselect);
      if (response.status === 200) {
        const countryList = response.data.data;
        setFormValues(prev => ({
          ...prev,
          countryoption: countryList.map(company => ({ value: company.id, label: company.name }))
        }));
      }
    } catch (error) {
      handleApiError(error, null, null, true, showErrorToast);
    } finally {
      setFormValues(prev => ({ ...prev, countryLoading: false }));
    }
}, [showErrorToast,formValues.countryoption.length,]);

  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={data.flag}
        alt=""
        style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
      />
      <p className="customsinglep">{`+${data.dial_code}`}</p>
    </div>
);
const customOption = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={props.data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginRight: '10px' }}
          />
          {props.data.label}
        </div>
      </components.Option>
    );
};
const ip = useCallback(async()=>{
  if(signupclick){
  if (!ipCountryCode) {
    await getUserIpCountryCode();
  }
  await fetchCountryCode();
}
},[signupclick,getUserIpCountryCode, ipCountryCode,fetchCountryCode,])
useEffect(() => { 
 ip();
}, [ip]);
useEffect(()=>{
  if(signupclick){
    countryList();
  }
},[countryList,signupclick]);
 const handleKeyPress1 = (e) => {
  const char = String.fromCharCode(e.which);
  if (!/^[a-zA-Z ]$/.test(char)) {
    e.preventDefault();
    setInlineError((prevState) => ({
      ...prevState,
      nameerror: 'Only alphabetic characters and spaces are allowed',
    }));
  } else {
    setInlineError((prevState) => ({
      ...prevState,
      nameerror: '',
    }));
  }
};
const handlecountry = (selectedOption) => {
  setFormValues((prev)=>({
    ...prev,
    country:selectedOption
  }));
  setError422('');
}
const customStyles1 = (hasValue) => ({
  control: (provided,state) => ({
    ...provided,
    borderColor: hasValue ? 'black' : '#DCDCDC',
    height:'46px',
    fontSize:'14px',
    borderRadius:'.25rem',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: hasValue ? 'black' : '#DCDCDC',
    },
  }),container: (provided) => ({
    ...provided,
    width:'100%',
    maxWidth:'446px',
    borderRadius:'.25rem',
    marginTop:'.5rem', 
  }),placeholder: (provided) => ({
    ...provided,
    // color: '#ABABAB',
    width: '100%',
    fontSize:'12px',
    fontWeight:'500',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'red',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: hasValue ? 'black' : '#DCDCDC',
    svg: {
      fill: hasValue ? 'black' : '#B9B9B9',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
});
const customStyles2 = {
  control: (provided) => ({
    ...provided,
    width:formValues.isLoading3 ? '30px' : `${selwidth}px` ,
    height: '100%',
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 8px',
    display: 'none'
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'black',
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
    display: 'flex',
    alignItems: 'center',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  menu: (provided) => ({
    ...provided,
    width: '216px',
  }),
}
const handleCountryChangesign = (selectedOption) => {
  const selectedCountryData = formValues.countryOptions.find(
    (country) => country.value === selectedOption.value
  );

  if (selectedCountryData) {
      setFormValues((prevState) => ({
          ...prevState,
          mcc_id: selectedCountryData.mcc_id,
          dial_code: `+${selectedCountryData.dial_code}`,
          country_code: selectedCountryData.country_code,
        }));

    const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
    if (maxLength !== null && mobileInputRef.current) {
      mobileInputRef.current.setAttribute('maxlength', maxLength);
    }
  }
  setError422('');
};

const handlePaste = (e) => {
  const pastedText = e.clipboardData.getData('text');
  let numericPastedText = pastedText.replace(/[^0-9]/g, '');

  // Remove leading '0' if present
  if (numericPastedText.startsWith('0')) {
      numericPastedText = numericPastedText.slice(1);
  }

  const maxLength = mobileInputRef.current ? mobileInputRef.current.getAttribute('maxlength') : 15;
  if (numericPastedText.length > maxLength) {
      numericPastedText = numericPastedText.slice(0, maxLength);
  }

  setFormValues((prev)=>({
      ...prev,username:numericPastedText
  }));
  setError422('');
  e.preventDefault();
};
const handleKeyPresssign = (e) => {
  // Allow only numeric characters (0-9) and some specific control keys
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

  if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};
const handlePhoneNumberChangesign = (value) => {
  if (value.startsWith('0')) {
    value = value.slice(1);
  }
  const maxLength = mobileInputRef.current.getAttribute('maxlength');
  if (value.length > maxLength) {
    value = value.slice(0, maxLength);
  }
  setFormValues((prev)=>({
    ...prev,mobile:value
  }))
  setError422('')
};
const calculateWidth = (label) => {
  const tempDiv = document.createElement('p');
  tempDiv.style.position = 'absolute';
  tempDiv.style.visibility = 'hidden';
  tempDiv.style.fontSize = '14px';
  tempDiv.style.fontWeight = '400';
  tempDiv.style.whiteSpace = 'nowrap';
  tempDiv.style.margin = '0';
  document.body.appendChild(tempDiv);

  tempDiv.innerText = label;
  const newWidth = tempDiv.offsetWidth;
  document.body.removeChild(tempDiv);

  return newWidth;
};

const selectWidth = useCallback(() => {
  const label = formValues.dial_code || '';
  const newWidth = Math.round(calculateWidth(label));
  setSelwidth(newWidth + 45);
}, [formValues.dial_code]);

useEffect(() => {
  selectWidth();
}, [selectWidth]);

useEffect(() => {
  const handleResize = () => {
    selectWidth();
  };
  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, [selectWidth]);
const [isInvalid, setIsInvalid] = useState({
  letter: true,
  capital: true,
  special: true,
  number: true,
  length: true,
});
const handlepasswordchange = (event) => {
  const newPasswords = event.target.value;
  setFormValues((prev)=>({
    ...prev,passwordnew:newPasswords
  }));
  const lowercaseLetters = /[a-z]/g;
  const uppercaseLetters = /[A-Z]/g;
  const specialCharacters = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-|=]/g;
  const numbers = /[0-9]/g;
  setIsInvalid({
      letter: !newPasswords.match(lowercaseLetters),
      capital: !newPasswords.match(uppercaseLetters),
      special: !newPasswords.match(specialCharacters),
      number: !newPasswords.match(numbers),
      length: newPasswords.length < 8,

  });
}
const handleFocus = () => {
    setIsFocused(true);
};
const getPasswordValidationClass = (isValid) => {
    return isValid ? 'valid' : 'invalid';
};
const handleBlur = () => {
    setIsFocused(false);
};
const togglePasswordVisibilitynew = () => {
  setShowPasswordnew(!showPasswordnew);
}
const togglePasswordVisibilitycur = () => {
  setShowPasswordcur(!showPasswordcur);
}
const handleKeyDown = (e) => {
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
  if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault(); // Prevent the default action
    setError422('Please enter only numbers'); // Set error message
  } else {
    setError422(''); // Clear error if valid key is pressed
  }
};
const checksign = useCallback(()=>{
  const valid = (
    formValues.name !== '' && formValues.email !== '' && formValues.mobile !== '' && formValues.country !== '' && formValues.passwordnew !== '' && formValues.passwordcurrent !== ''
  )
  setEnabledsignup(valid);
},[ formValues.name,formValues.email,formValues.mobile,formValues.country,formValues.passwordnew,formValues.passwordcurrent]);
useEffect(()=>{
  checksign();
},[checksign])
const signup = async(e) => {
  e.preventDefault();
      if (formValues.passwordnew !== formValues.passwordcurrent) {
        setInlineError((prevState) => ({
          ...prevState,
          passwordcurrenterror: 'Passwords do not match',
        }));
        return false;
       }else{
        setInlineError((prevState) => ({
          ...prevState,
          passwordcurrenterror: '',
        }));
       }
      
      const gmailUsernamePattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if(!gmailUsernamePattern.test(formValues.email)){
        setInlineError((prevState) => ({
          ...prevState,
          emailerror: 'Invalid Email Address',
        }));
        return;
      }else{
        setInlineError((prevState) => ({
          ...prevState,
          emailerror: '',
        }));
      }
      const data = {
        name: formValues.name,
        email: formValues.email,
        mobile:formValues.mobile,
        mcc_id: formValues.mcc_id,
        country_id: formValues.country.value,
        password: formValues.passwordnew,
        confirmpassword:formValues.passwordcurrent,
        verify: 'email',
       }
      try{
        setIsLoading4(true);
         const response = await axiosInstance.post(signupapiurl,data);
         if(response.status === 200){
          setOtp('');
          setShow1(true);
          setIsLoading4(false);
          // startCountdown();
         }
      }catch(error){
        handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
      }finally{
        setIsLoading4(false);
      }
    }
    const resendotp = () => {

    }
    const checkverfiy = useCallback(()=> {
      const valid = (
        otp !== ''
      )
      setEnabledverify(valid);
    },[otp])
    useEffect(()=>{
      checkverfiy();
    },[checkverfiy]);
    const signupverify = async() => {
      const data = {
        otp : otp,
        device_type: 'web'
      }
       try{
        setIsLoading4(true);
          const response = await axiosInstance.post(signupverifyapiurl,data);
          if(response.status === 200){
            setOtp('');
            let X_AuthUser = response.data.data.authuser;
            const params = process_params();
          if (params.redirecturl) {
            const regex = /\/u\/([^/]+)/; 
            const matchall = params.allparams;
            const match1 = matchall.replace("redirecturl=", '');
            const url = match1.replace("&",'?');
            const match = url.match(regex); 
            if(match){
              const updatedPathname = url.replace(new RegExp(`/u/${match[1]}/`), `/u/${X_AuthUser}/`);
              window.location.href = updatedPathname;
            }
            // else if(params.redirecturl === 'https://myaccount.evzone.app' || params.redirecturl === 'https://myaccount.evzone.app/'){
            //   window.location.href = `https://myaccount.evzone.app/u/${X_AuthUser}/services`;
            // }
            else{
              const urlObj = new URL(url);
              urlObj.searchParams.set('x_authuser', X_AuthUser);
              window.location.href = urlObj.toString();
            }
          } else {
            window.location.href = `${myaccountdomainurl}/u/${X_AuthUser}/services`;
          }
          setIsLoading4(false);
          }
       }catch(error){
        handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
       }finally{
        setIsLoading4(false);
       }
    }
  return (
    <>
      
    <input type="text" className={`form-control logininputsign mt-3 ${formValues.name ? 'logininputsign-active' : ''}`} onKeyPress={handleKeyPress1} value={formValues.name} onChange={(e)=>{setError422('');setFormValues(prev => ({ ...prev, name: e.target.value }))}} placeholder="Name"></input>
    {inlineerror.nameerror && <div className="errors">{inlineerror.nameerror}</div>}
    <Select  options={formValues.countryoption}  value={formValues.country} isLoading={formValues.countryloading}  isSearchable={true} placeholder="Select Country" onChange={handlecountry} styles={customStyles1(Boolean(formValues.country))} />
    <div className="signupdivsplit">
            <div className="mt-2" style={{width:'100%',maxWidth:'446px'}}>  
            <input type="email" value={formValues.email} onChange={(e)=>{setFormValues((prevState) => ({...prevState,email: e.target.value,}));setInlineError((prevState) => ({...prevState,emailerror: '',}));setError422('')}} className={`form-control logininputsign ${formValues.email ? 'logininputsign-active' : ''}`}  placeholder="Email"></input>
            {inlineerror.emailerror && <div className="errors">{inlineerror.emailerror}</div>}
            </div>
            <div className="mt-2" style={{ position: 'relative', display: 'flex', alignItems: 'center',width:'100%',maxWidth:'446px', }}>
            <div style={{ position: 'absolute', left: 0, }}>
            <Select options={formValues.countryOptions} onChange={handleCountryChangesign} value={formValues.countryOptions.find(option => option.value === formValues.country_code)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={formValues.isLoading3} styles={customStyles2}/>
            </div>
            <div style={{ height: '30px', width: '2px', backgroundColor: '#ccc', margin: '0 4px', position: 'absolute', left: `${selwidth}px`, top: '50%', transform: 'translateY(-50%)' }}></div>
             {/* <p style={{ position: 'absolute', left:'40px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{formValues.dial_code}</p> */}
            <input type="text" className={`form-control logininputsign ${formValues.mobile ? 'logininputsign-active' : ''}`} id="mobile" ref={mobileInputRef} style={{width: '100%',paddingLeft:`${selwidth + 14}px`}} placeholder="Mobile Number" value={formValues.mobile} name="mobile" onPaste={(e) => handlePaste(e)} onKeyPress={handleKeyPresssign} onChange={(e) => handlePhoneNumberChangesign(e.target.value)}/>
            </div>
            </div>
            <div className="signupdivsplit">
            <div className=" mt-2 " style={{position:'relative',width:'100%',maxWidth:'446px'}}>
            <input type={showPasswordnew ? 'text' : 'password'} value={formValues.passwordnew} onFocus={handleFocus} onBlur={handleBlur}  onChange={handlepasswordchange} className={`form-control logininputsign ${formValues.passwordnew ? 'logininputsign-active' : ''}`}  
                placeholder="Password" name="password"  />
                {isFocused && (
                <div id="message4" className={`password-message ${isFocused ? "initial-focus" : ""}`}>
                    <h6>Password must contain the following:</h6>
                    <p className={getPasswordValidationClass(isInvalid.letter)}>
                        At least a Lowercase
                    </p>
                    <p className={getPasswordValidationClass(isInvalid.capital)}>
                        At least a Capital (Uppercase)
                    </p>
                    <p className={getPasswordValidationClass(isInvalid.special)}>
                        At least Special Character
                    </p>
                    <p className={getPasswordValidationClass(isInvalid.number)}>A number</p>
                    <p className={getPasswordValidationClass(isInvalid.length)}>
                        Minimum 8 Characters
                    </p>
                </div>
                )}
              <p className="toggle-password" onClick={togglePasswordVisibilitynew}style={{ position: 'absolute', marginRight: '10px', top: '0%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                {showPasswordnew ? (
                  formValues.passwordnew ? (
                    <img src={eye} className='eye'style={{width:'16px',height:'auto'}} alt="Show Password" />
                  ) : (
                    <img src={eyegrey} className='eye'style={{width:'16px',height:'auto'}} alt="Show Password" />
                  )
                ) : (
                  formValues.passwordnew ? (
                  <img src={eyeslash} className='eye' style={{width:'16px',height:'auto'}} alt="Hide Password" />
                  ) : (
                    <img src={eyeslashgrey} className='eye' style={{width:'16px',height:'auto'}} alt="Hide Password" />
                  )
                )}
              </p>
            </div>
            <div style={{width:'100%',maxWidth:'446px'}}>
        <div className="mt-2" style={{position:'relative'}}>
            <input type={showPasswordcur ? 'text' : 'password'} value={formValues.passwordcurrent} onChange={(e)=>{setFormValues((prev)=>({...prev,passwordcurrent:e.target.value}));setInlineError((prevState) => ({...prevState,passwordcurrenterror: '',}));setError422('')}} className={`form-control logininputsign ${formValues.passwordcurrent ? 'logininputsign-active' :''}`} id="userpassword"
                placeholder="Confirm Password" name="password"  />
            <p className="toggle-password" onClick={togglePasswordVisibilitycur}style={{ position: 'absolute', marginRight: '10px', top: '0%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                {showPasswordcur ? (
                  formValues.passwordcurrent ? (
                  <img src={eye} className='eye' style={{width:'16px',height:'auto'}} alt="Show Password" />
                  ) : (
                    <img src={eyegrey} className='eye' style={{width:'16px',height:'auto'}} alt="Show Password" />
                  )
                ) : (
                  formValues.passwordcurrent ? (
                  <img src={eyeslash} className='eye' style={{width:'16px',height:'auto'}} alt="Hide Password" />
                  ) : (
                  <img src={eyeslashgrey} className='eye' style={{width:'16px',height:'auto'}} alt="Hide Password" />
                  )
                )}
            </p>
            </div>
             {inlineerror.passwordcurrenterror && <div className="errors">{inlineerror.passwordcurrenterror}</div>}
        </div>
            </div>
            <input type="text" className="form-control logininputsign mt-2" placeholder="Referral Code"></input>
            <div className="mt-2" style={{width:'100%',display:'flex',alignItems:'center',maxWidth:'446px',gap:'5px'}}>
            <input type="checkbox" ></input>
            <p className="singupterms">You must agree to our privacy and terms of use</p>
            </div>
            {error422 && (<div className='errors'>{error422}</div>)}
            <button style={{backgroundColor:enabledsignup ? '#F77F00' : '#EAEAEA',color:enabledsignup ? 'white' : 'hsl(0, 0%, 20%)',cursor:enabledsignup ? 'pointer' : 'not-allowed'}} disabled={!enabledsignup} onClick={signup} className="loginsubmit mt-2">Sign up</button>

            
     <Modal show={show1} dialogClassName="document-dialog1signup" contentClassName="document-content1signup"  centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
            <div className='d-flex justify-content-center flex-column align-items-center'>
            <h6 className='resetpassh6'>Enter Your OTP</h6>
            <p className='resetpassp resetpassp1'>Enter the 6-didit code sent<br></br>
            to {getMaskedEmail(formValues.email)}<br></br>
            Did not receive OTP ?<strong className={`${countdown > 0 ? 'inactiveotpsssp' : 'activeotpsssp'}`} style={{ cursor: countdown > 0 ? 'not-allowed' : 'pointer' }} onClick={countdown <= 0 ? resendotp : null}>Resend OTP</strong></p>
            <input type='text' value={otp} onKeyDown={handleKeyDown} onChange={(e)=>{setOtp(e.target.value);setError422('')}} placeholder='Enter OTP' className={`form-control mt-3 logininputsign ${otp ? 'logininputsign-active' : ''}`}></input>
            <button type="button" className='resendotp mt-4'> {countdown > 0 ? `${countdown}S` : `${countdown}S`}</button>
            {error422 && (<div className='errors'>{error422}</div>)}
            <button style={{backgroundColor:enabledverify ? '#F77F00' : '#EAEAEA',color:enabledverify ? 'white' : 'hsl(0, 0%, 20%)',cursor:enabledverify ? 'pointer' : 'not-allowed'}} disabled={!enabledverify} onClick={signupverify} className="loginsubmit mt-3">Submit</button>
            </div>
        </Modal.Body>
        </Modal>  
    </>
  )
})

export default WithToast(Signup);