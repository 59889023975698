import axiosInstance from "../axiosinstance"
import {logoutapi, logoutremoveapi, refreshapi, sidapi} from '../url';
const sidapiurl = sidapi();
const logoutremoveapiurl = logoutremoveapi();
const logoutapiurl = logoutapi();
const refreshapiurl = refreshapi();
export const sid = async() => {
    return await axiosInstance.get(sidapiurl);
}
export const accountremove = async (authuser) => {
    const config = {
      headers: {
        'X-Authuser': authuser,  // Pass the dynamic X-Authuser header
        'Content-type': 'application/json',
        'Accept': 'application/json',
      },
      withCredentials: true
    };
  
    return await axiosInstance.post(logoutremoveapiurl, null, config);
};
export const accountlogout = async(authuser) => {
    const config = {
        headers: {
          'X-Authuser': authuser,  // Pass the dynamic X-Authuser header
          'Content-type': 'application/json',
          'Accept': 'application/json',
        },
        withCredentials: true
      };
    
      return await axiosInstance.post(logoutapiurl, null, config);
}
export const accountrefresh = async(authuser,data) => {
  const config = {
    headers: {
      'X-Authuser': authuser,  // Pass the dynamic X-Authuser header
      'Content-type': 'application/json',
      'Accept': 'application/json',
    },
    withCredentials: true
  };

  return await axiosInstance.post(refreshapiurl, data, config);
}