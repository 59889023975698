import React, { useCallback, useEffect, useState } from 'react';
import closeicon from '../images/Group 1728.png';
import doticon from '../images/icon-03.svg';
import { handleApiError, process_params } from '../api/commonfunctions/common';
import Modal from 'react-bootstrap/Modal';
import WithToast from '../api/context_api_functions/withtoast';
import blueeye from '../images/eye.png';
import blueeyeslash from '../images/eye-slash.png';
import plus from '../images/plus.svg';
import { accountremove,accountlogout,sid, accountrefresh } from '../api/api_functions/apis';
import { publicDomainimage} from '../api/url';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { myaccountdomain } from '../api/url';
import exportimg from '../images/export-01.png';
const myaccountdomainurl = myaccountdomain();
const publicDomainimageurl = publicDomainimage();
function Listaccounts({setIsLoading4,showErrorToast}) {
  const [account, setAccount] = useState([]);
  const [userStatus, setUserStatus] = useState('');
  const naviagte = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [user,setUser] = useState(null);
  const [show,setShow]= useState(false);
  const [isenabled,setEnabled] = useState(false);
  const [error422,setError422] = useState('');
  const [formValues,setFormValues] = useState({
    password:'',passwordshow:false
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setError422('');
};
const passwordvisiblity = () => {
  setFormValues((prev)=>({
      ...prev,passwordshow:!prev.passwordshow
  }))
}
const getredirect = () => {
  const params = process_params();
  let redirectUrl = '/auth';
  if (params.allparams) {
    const formattedParams = params.allparams.replace("?", "&"); // Ensure correct format
    redirectUrl += `?${formattedParams}`;
  }
  naviagte(redirectUrl); 
}
  const userAccounts = useCallback(() => {
    sid()
    .then(res => {
      if (res.data.data.length === 0) {
        getredirect();
      } else {
        setAccount(res.data.data);
      }
    })
      .catch((error) => handleApiError(error, null, null, true, showErrorToast));
  }, [showErrorToast,naviagte]);

  useEffect(() => {
    userAccounts();
  }, []);
  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };
  const deleteaccount = async(authuser) => {
    if(!authuser) return;
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this account?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading4(true);
          const response = await accountremove(authuser);
          if(response.status === 200){
             await userAccounts(); 
             setIsLoading4(false);
          }
        } catch (error) {
          handleApiError(error, setIsLoading4, null, true, showErrorToast);
        }finally{
          setIsLoading4(false);
        }
      }
    });
  }
  const logoutaccount = async(authuser) => {
    if(!authuser) return;
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to logout this account?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading4(true);
          const response = await accountlogout(authuser);
          if(response.status === 200){
             await userAccounts(); 
             setIsLoading4(false);
          }
        } catch (error) {
          handleApiError(error, setIsLoading4, null, true, showErrorToast);
        }finally{
          setIsLoading4(false);
        }
      }
    });
  }
  const signin = async(status,authuser) => {
    setShow(true);
    setUserStatus(status);
    setUser(authuser);
  }
  const handleclose = () => {
    setShow(false);
    setUserStatus('');
    setError422('');
    setFormValues((prev)=>({
      ...prev,password:''
    }))
    setUser(null);
  }
  const check = useCallback(()=>{
    const valid = (
      formValues.password !== '' 
    )
    setEnabled(valid);
  },[formValues.password]);
  useEffect(()=>{
      check();
  },[check]);
  const save = async() => {
     const data = {
      password : formValues.password
     }
     try{
      setIsLoading4(true);
      const response = await accountrefresh(user,data);
      if(response.status === 200){
        const params = process_params();
        if (params.redirecturl) {
          const regex = /\/u\/([^/]+)/; 
          const matchall = params.allparams;
          const match1 = matchall.replace("redirecturl=", '');
          const url = match1.replace("&",'?');
          const match = url.match(regex); 
          if(match){
            const updatedPathname = url.replace(new RegExp(`/u/${match[1]}/`), `/u/${user}/`);
            window.location.href = updatedPathname;
          }
          else{
            const urlObj = new URL(url);
            urlObj.searchParams.set('X_AuthUser', user);
            window.location.href = urlObj.toString();
          }
        } else {
          window.location.href = `${myaccountdomainurl}/u/${user}/services`;
        }
      }
     }catch(error){
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
     }finally{
      setIsLoading4(false);
     }
  }
  const accountresume = (authuser,item) => {
    if(!item.logout && !item.exp){
    const params = process_params();
    if (params.redirecturl) {
      const regex = /\/u\/([^/]+)/; 
      const matchall = params.allparams;
      const match1 = matchall.replace("redirecturl=", '');
      const url = match1.replace("&",'?');
      const match = url.match(regex); 
      if(match){
        const updatedPathname = url.replace(new RegExp(`/u/${match[1]}/`), `/u/${authuser}/`);
        window.location.href = updatedPathname;
      }
      else{
        const urlObj = new URL(url);
        urlObj.searchParams.set('x_authuser', authuser);
        window.location.href = urlObj.toString();
      }
    } else {
      window.location.href = `${myaccountdomainurl}/u/${authuser}/services`;
    }
  }
  };
  const addaccount = () => {
    getredirect();
  }
  return (
    <>
      <div className='d-flex justify-content-center align-items-center'>
        <div className="forgotbox listaccountbox mt-4">
          <div className='resetpasspad12'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <div className='d-flex justify-content-between w-100 align-items-center '>
                <div>
                <h6 className='Manageaccounth6'>Manage Accounts</h6>
                <p className='managep mb-0'>Switch and Mangae your accounts</p>
                </div>
                <img src={closeicon} alt='closeicon' className='manageclose'></img>
              </div>
              {account.map((item, index) => {
                  const statusBadge = item.exp
                ? <span className="managebadge badge-warning">Expired</span>
                : item.logout
                ? <span className="managebadge badge-secondary">Signed out</span>
                : <span className="managebadge badge-success">Active</span>;

                return (
                  <div className={`manageprofilediv`} onClick={()=>accountresume(item.authuser,item)} key={index}>
                    <div className='manageprofilediv_1sthalf'>
                      <div className='manageprofile me-2'>
                        <img src={`${publicDomainimageurl}/${item.image}`} alt='profile' className='manage_profileimagge' ></img>
                      </div>
                      <div className='manageprofilewidth'>
                        <div className='d-flex align-items-center '>
                        <p className='mb-0 manageprofilename me-2'>{item.name}</p>
                        {statusBadge}
                        </div>
                        <p className='mb-0 manageprofilegmail'>{item.email}</p>
                      </div>
                    </div>
                      <div className='position-relative d-flex align-items-center'>
                      {!item.logout && !item.exp ? (
                         <>
                          <img src={exportimg} alt='dot_icon' className='dot_icon'  onClick={()=>logoutaccount(item.authuser)}></img>
                         </>
                      ) : (
                        <>
                         <img src={doticon} alt='dot_icon' className='dot_icon' onClick={() => toggleDropdown(index)} ></img>
                      {dropdownOpen === index && (
                      <div className="dropdown-menu_dot_icon"> 
                        {item.exp && (
                          <>
                          <p className="dropdown-item_dot_icon mb-0" onClick={()=>logoutaccount(item.authuser)} >Sign out</p>
                         <hr className='managehr_dot'></hr>
                        <p className="dropdown-item_dot_icon mb-0" onClick={()=>signin('expired',item.authuser)}>Sign in Again</p>
                          </>
                        )}
                        {item.logout && (
                         <> 
                        <p className="dropdown-item_dot_icon mb-0" onClick={()=>deleteaccount(item.authuser)}>Delete Account</p>
                         <hr className='managehr_dot'></hr>
                        <p className="dropdown-item_dot_icon mb-0" onClick={()=>signin('logged out',item.authuser)}>Sign in Again</p>
                        </>
                        )}
                      </div>
                      )}
                        </>
                      )}  
                    </div>
                  </div>
                );
              })}

              <button className='manageaddaccount mt-4' onClick={addaccount}><img src={plus} onClick={addaccount} alt='plus' className='plus' ></img>Account</button>
            </div>
          </div>
        </div>
      </div>
             
     <Modal show={show} dialogClassName="document-dialog1signup" contentClassName="document-content1signup" onHide={handleclose} centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
        <div className='d-flex align-items-center justify-content-between '>
          <h6 className='mb-0 Manageaccounth6'>Sign in Again</h6>
          <img src={closeicon} onClick={handleclose} alt='closeicon' className='manageclose'></img>
        </div>
        <div className='d-flex justify-content-center align-items-center flex-column'>
            <p className='managemodal_p mb-0 mt-4'>
              {userStatus === 'expired' ? 'Your session has expired. Please sign in again to refresh your account' : 'You are logged out. Please sign in to continue'}
            </p>
          <div className='position-relative w-100 mt-4'>
       <input type={formValues.passwordshow ? 'text' : 'password'} onChange={handleChange} name='password' className={`form-control forgot_input_40 forgot_input_40top  ${formValues.password ? 'forgot_input_40_active' : 'forgot_input_40_not'}`} value={formValues.password}></input>
       <p className="toggle-passwordss" onClick={passwordvisiblity} >
        {formValues.passwordshow ? (
            <img src={blueeye} className='eye' style={{ width: '18px', height: 'auto' }} alt="Hide Password" />
        ) : (
            <img src={blueeyeslash} className='eye' style={{ width: '18px', height: 'auto' }} alt="Show Password" />
        )}
       </p>
       </div>
       {error422 && (<div className='errors'>{error422}</div>)}
        <div className='d-flex align-items-center justify-content-end mt-4 w-100'>
           <button className='me-3 managepopbuttons_cancel' onClick={handleclose}>Cancel</button>
           <button className={`managepopbuttons_save ${isenabled ? 'managepopbuttons_save_active' : ''}`} disabled={!isenabled} onClick={save}>Submit</button>
        </div>
        </div>
        </Modal.Body>
        </Modal>
    </>
  );
}

export default WithToast(Listaccounts);
