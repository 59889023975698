import React,{useState,useCallback,useRef,useEffect, memo} from 'react';
import WithToast from '../api/context_api_functions/withtoast';
import { handleApiError, process_params } from '../api/commonfunctions/common';
import { useIpContext } from '../api/context_api_functions/ipcontext';
import { country_codeapi, loginwithotpverifyapi,loginwithotpapi } from '../api/url';
import axiosInstance from '../api/axiosinstance';
import Select, { components } from 'react-select';
import editimage from '../images/pencil-edit-button.svg';
const loginwithotpverifyapiurl = loginwithotpverifyapi();
const loginwithotpapiurl = loginwithotpapi();
const mcclistapiurl = country_codeapi();
const Loginwithotp = memo(({showErrorToast,setShowForm1,setShowForm2,setShowForm3,toggleLoginForms,setIsLoading4,myaccountdomainurl})=>{
    const mobileInputRef = useRef(null);
    const inputRef = useRef(null);
    const [showCountryInput, setShowCountryInput] = useState(false);
    const [islogged,setIslogged] = useState(false);
    const [islogged1,setIslogged1] = useState(false);
    const [otpfieldfalse,setOtpfieldfalse] = useState(false);
    const [isCountryCodeFetched, setIsCountryCodeFetched] = useState(false);
    const [selwidth, setSelwidth] = useState(0);
    const [formValues,setFormValues] = useState({
        countryOptions:[],country_code:'',mcc_id:'',dial_code:'',username:'',isLoading:false,otp:'',showPassword:false
    });
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormValues(prevValues => ({
        ...prevValues,
        [name]: value
      }));
      setError422('');
    };  
    const [error,setError] = useState({
        usernameerror:'',passworderror:''
    })
    const [error422,setError422] = useState('');
    const { ipCountryCode, getUserIpCountryCode } = useIpContext();
    const updateExampleNumber = useCallback((iso2) => {
        if (window.intlTelInputUtils) {
          try {
            const exampleNumber = window.intlTelInputUtils.getExampleNumber(
              iso2,
              true,
              window.intlTelInputUtils.numberFormat.E164
            );
            const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
            const maxLength = cleanedNumber.length;
            return maxLength;
          } catch (error) {
            console.error('Error fetching example number for country code:', iso2, error);
            return 15; // Fallback to a default length
          }
        } else {
          console.error('intlTelInputUtils is not loaded');
          return 15; // Fallback to a default length
        }
      }, []);
      const fetchCountryCode = useCallback(async () => {
        if (ipCountryCode){ 
        if (formValues.countryOptions.length > 0) return;
        setFormValues((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
      
        try {
          const response = await axiosInstance.get(mcclistapiurl);
          const data = response.data;
      
          if (data.message === 'Success') {
            const mccList = data.data;
            const codes = mccList.map((mcc) => ({
              country_code: mcc.country_code,
              dial_code: mcc.code,
              mcc_id: mcc.id,
              label: `${mcc.name} (+${mcc.code})`,
              value: mcc.country_code,
              flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
            }));
      
            setFormValues((prevState) => ({
              ...prevState,
              countryOptions: codes,
            }));
            let code = ipCountryCode;
            const selectedCountryData = codes.find(
              (country) => country.country_code === code
            );
      
            if (selectedCountryData) {
              setFormValues((prevState) => ({
                ...prevState,
                mcc_id: selectedCountryData.mcc_id,
                dial_code: `+${selectedCountryData.dial_code}`,
                country_code: selectedCountryData.country_code,
              }));
      
              const maxLength =  updateExampleNumber(selectedCountryData.country_code.toLowerCase());
              if (maxLength !== null && mobileInputRef.current) {
                mobileInputRef.current.setAttribute('maxlength', maxLength);
              }
            }
          } else {
            console.error('Failed to fetch country codes');
          }
        } catch (error) {
          handleApiError(error, null, null, true, showErrorToast);
        } finally {
          setFormValues((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        }
      }
    
    }, [formValues.countryOptions.length, updateExampleNumber, showErrorToast,ipCountryCode]);
    // const signformcomponent = () => {
    //    signform();
    // }
    // const signupformcomponent = () => {
    //   //  signupform();
    // }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            login(e);
        }
    };
    useEffect(() => {
        if (showCountryInput) {
            mobileInputRef.current?.focus();
        } else {
            inputRef.current?.focus();
        }
    }, [showCountryInput]);
    const handleUsernameChange = async(e) => {
        let inputUsername = e.target.value.replace(/\s/g, '');
        if (inputUsername.startsWith('0')) {
            inputUsername = inputUsername.slice(1);
        }
        if (/^\+?\d+$/.test(inputUsername)) {
            if (!isCountryCodeFetched) {
                setShowCountryInput(true);
                if (mobileInputRef.current) {
                    const maxLength = mobileInputRef.current.getAttribute('maxlength');
                    if (inputUsername.length > maxLength) {
                        inputUsername = inputUsername.slice(0, maxLength);
                    }
                }// Set the flag to indicate that API call has been made
                if (!ipCountryCode) {
                    getUserIpCountryCode();
                }
                await fetchCountryCode();
                setIsCountryCodeFetched(true); 
            }
        } else {
            setShowCountryInput(false);
            setIsCountryCodeFetched(false); // Reset the flag if inputUsername is not a valid number
        }
        setFormValues((prev)=>({
            ...prev,username:inputUsername
        }));
        setError((prev)=>({
            ...prev,usernameerror:'',passworderror:''
        }));
        // setUsername(inputUsername);
        // setUsernameError('');
        // setpasswordError('');
        setError422('');
    };
    const handleCountryChange = (selectedOption) => {
        const selectedCountryData = formValues.countryOptions.find(
          (country) => country.value === selectedOption.value
        );
    
        if (selectedCountryData) {
            setFormValues((prevState) => ({
                ...prevState,
                mcc_id: selectedCountryData.mcc_id,
                dial_code: `+${selectedCountryData.dial_code}`,
                country_code: selectedCountryData.country_code,
              }));
    
          const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
          if (maxLength !== null && mobileInputRef.current) {
            mobileInputRef.current.setAttribute('maxlength', maxLength);
          }
        }
        setError((prev)=>({
            ...prev,usernameerror:'',passworderror:''
        }));
        setError422('');
      };
      
    const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData('text');
        let numericPastedText = pastedText.replace(/[^0-9]/g, '');
  
        // Remove leading '0' if present
        if (numericPastedText.startsWith('0')) {
            numericPastedText = numericPastedText.slice(1);
        }
  
        const maxLength = mobileInputRef.current ? mobileInputRef.current.getAttribute('maxlength') : 15;
        if (numericPastedText.length > maxLength) {
            numericPastedText = numericPastedText.slice(0, maxLength);
        }
  
        setFormValues((prev)=>({
            ...prev,username:numericPastedText
        }));
        setError((prev)=>({
            ...prev,usernameerror:'',passworderror:''
        }));
        setError422('');
        e.preventDefault();
    };
    useEffect(()=>{
        if(ipCountryCode){
            fetchCountryCode();
        }
    },[fetchCountryCode,ipCountryCode]);
    const customSingleValue = ({ data }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
          />
          {/* {data.label} */}
        </div>
    );
    const customOption = (props) => {
        return (
          <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={props.data.flag}
                alt=""
                style={{ width: '25px', height: 'auto', marginRight: '10px' }}
              />
              {props.data.label}
            </div>
          </components.Option>
        );
    };
    const customStyles = {
        control: (provided) => ({
          ...provided,
          width:'80px',
          height: '46px',
          border: 'none',
          boxShadow: 'none',
          background: 'transparent',
          display: 'flex',
          alignItems: 'center',
          }),
          dropdownIndicator: (provided) => ({
          ...provided,
          padding: '0 8px',
          }),
          indicatorSeparator: () => ({
          display: 'none',
          }),
          singleValue: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
          }),
          valueContainer: (provided) => ({
          ...provided,
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          }),
          input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          }),
          placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          }),
          menu: (provided) => ({
          ...provided,
          minWidth: '300px',})  
    }
    const calculateWidth = (label) => {
      const tempDiv = document.createElement('p');
      tempDiv.style.position = 'absolute';
      tempDiv.style.visibility = 'hidden';
      tempDiv.style.fontSize = '14px';
      tempDiv.style.fontWeight = '400';
      tempDiv.style.whiteSpace = 'nowrap';
      tempDiv.style.margin = '0';
      document.body.appendChild(tempDiv);
  
      tempDiv.innerText = label;
      const newWidth = tempDiv.offsetWidth;
      document.body.removeChild(tempDiv);
  
      return newWidth;
    };

    const selectWidth = useCallback(() => {
      const label = formValues.dial_code || '';
      const newWidth = Math.round(calculateWidth(label));
      setSelwidth(newWidth + 82);
    }, [formValues.dial_code]);
  
    useEffect(() => {
      selectWidth();
    }, [selectWidth]);
  
    useEffect(() => {
      const handleResize = () => {
        selectWidth();
      };
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [selectWidth]);
    const toggleLoginForm = () => {
        toggleLoginForms();
        // setShowForm1(true);
        // setShowForm3(false);
        // setShowForm2(false);
    }
    const check = useCallback(()=>{
      const valid = (
        formValues.username !== ''
      )
     setIslogged(valid);
    },[formValues.username])
     useEffect(() => {
      check();
    },[check]);
    const check1 = useCallback(()=>{
      const valid = (
        formValues.otp !== ''
      )
     setIslogged1(valid);
    },[formValues.otp])
     useEffect(() => {
      check1();
    },[check1]);
    const handleKeyDown = (e) => {
      const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
      // Allow numeric keys and specific control keys
      if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
        e.preventDefault(); // Prevent the default action
        setError422('Please enter only numbers'); // Set error message
      } else {
        setError422(''); // Clear error if valid key is pressed
      }
    };
    const sendotp = async (event) => {
      event.preventDefault();
      
      const gmailUsernamePattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let data;
      if (/^\+?\d+$/.test(formValues.username)) {
        if (!formValues.mcc_id) {
          setError((prev) => ({ ...prev, usernameerror: 'Select Your Country Again' }));
          return;
        }
        data = {
          username: formValues.username,
          mcc_id: formValues.mcc_id,
        };
      } else if (gmailUsernamePattern.test(formValues.username)) {
        data = {
          username: formValues.username,
        };
      } else {
        setError((prev) => ({ ...prev, usernameerror: 'Invalid Email Address' }));
        return;
      }
      try {
        setIsLoading4(true);
        const response = await axiosInstance.post(loginwithotpapiurl, data);
        if (response.status === 200) {
          setError422('');
          setFormValues((prev)=>({
            ...prev,otp:''
          }))
          setOtpfieldfalse(true);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 409) {
            const X_AuthUser = error.response.data.data.authuser;
            const params = process_params();
            if (params.redirecturl) {
              const regex = /\/u\/([^/]+)/; 
              const matchall = params.allparams;
              const match1 = matchall.replace("redirecturl=", '');
              const url = match1.replace("&",'?');
              const match = url.match(regex); 
              if(match){
                const updatedPathname = url.replace(new RegExp(`/u/${match[1]}/`), `/u/${X_AuthUser}/`);
                window.location.href = updatedPathname;
              }
              // else if(params.redirecturl === 'https://myaccount.evzone.app' || params.redirecturl === 'https://myaccount.evzone.app/'){
              //   window.location.href = `https://myaccount.evzone.app/u/${X_AuthUser}/services`;
              // }
              else{
                const urlObj = new URL(url);
                urlObj.searchParams.set('X_AuthUser', X_AuthUser);
                window.location.href = urlObj.toString();
              }
            } else {
              window.location.href = `${myaccountdomainurl}/u/${X_AuthUser}/services`;
            }
          } else if (error.response.status === 401) {
            const error_code = error.response.data.code;
            if (error_code === 'auth_failed') {
              setIsLoading4(false);
              setError422(error.response.data.mobile_error);
            }
          }else {
            handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
          }
        } else {
          // Handle network errors or any other unexpected errors
          handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
      } finally {
        setIsLoading4(false);
      }
    };
    const logwithootp = () => {
      setOtpfieldfalse(false);
    }
    const login = async (event) => {
      event.preventDefault();
      const data = {
        otp : formValues.otp,
        device_type :'web'
      }
      try {
        setIsLoading4(true);
        const response = await axiosInstance.post(loginwithotpverifyapiurl, data);
        if (response.status === 200) {
          const X_AuthUser = response.data.data.authuser;
          const params = process_params();
          if (params.redirecturl) {
            const regex = /\/u\/([^/]+)/; 
            const matchall = params.allparams;
            const match1 = matchall.replace("redirecturl=", '');
            const url = match1.replace("&",'?');
            const match = url.match(regex); 
            if(match){
              const updatedPathname = url.replace(new RegExp(`/u/${match[1]}/`), `/u/${X_AuthUser}/`);
              window.location.href = updatedPathname;
            }
            // else if(params.redirecturl === 'https://myaccount.evzone.app' || params.redirecturl === 'https://myaccount.evzone.app/'){
            //   window.location.href = `https://myaccount.evzone.app/u/${X_AuthUser}/services`;
            // }
            else{
              const urlObj = new URL(url);
              urlObj.searchParams.set('x_authuser', X_AuthUser);
              window.location.href = urlObj.toString();
            }
          } else {
            window.location.href = `${myaccountdomainurl}/u/${X_AuthUser}/services`;
          }
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 409) {
            const X_AuthUser = error.response.data.data.authuser;
            const params = process_params();
          if (params.redirecturl) {
            const regex = /\/u\/([^/]+)/; 
            const matchall = params.allparams;
            const match1 = matchall.replace("redirecturl=", '');
            const url = match1.replace("&",'?');
            const match = url.match(regex); 
            if(match){
              const updatedPathname = url.replace(new RegExp(`/u/${match[1]}/`), `/u/${X_AuthUser}/`);
              window.location.href = updatedPathname;
            }
            // else if(params.redirecturl === 'https://myaccount.evzone.app' || params.redirecturl === 'https://myaccount.evzone.app/'){
            //   window.location.href = `https://myaccount.evzone.app/u/${X_AuthUser}/services`;
            // }
            else{
              const urlObj = new URL(url);
              urlObj.searchParams.set('x_authuser', X_AuthUser);
              window.location.href = urlObj.toString();
            }
          } else {
            window.location.href = `${myaccountdomainurl}/u/${X_AuthUser}/services`;
          }
          } else if (error.response.status === 401) {
            const error_code = error.response.data.code;
            if (error_code === 'auth_failed') {
              setIsLoading4(false);
              setError422(error.response.data.mobile_error);
            }
          }else if(error.response.status === 422){
            const error_code = error.response.data.code;
            if (error_code === 'otp_retry') {
              setFormValues((prev)=>({
                ...prev,
                username:'',otp:'',
              }));
              setError422('');
                setOtpfieldfalse(false);
                setIsLoading4(false);  
            } 
            else{
              handleApiError(error, setIsLoading4, setError422, false, showErrorToast); 
            }
          } 
           else {
            handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
          }
        } else {
          // Handle network errors or any other unexpected errors
          handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
      } finally {
        setIsLoading4(false);
      }
    };
  return (
    <>
      {!otpfieldfalse ? (
      <> 
      {!showCountryInput ? (
      <div className="logininputboxcenter"> 
            <input className={`form-control logininputsign mt-4 ${formValues.username ? 'logininputsign-active' : ''}`} value={formValues.username} ref={inputRef} onChange={handleUsernameChange} onKeyPress={handleKeyPress} placeholder="Email or Mobile" type="text"></input>
            <div style={{ position: 'absolute', right: '30px', top: '68%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
            <p onClick={toggleLoginForm} style={{ fontSize: '10px',marginBottom:'0px', fontWeight: "500", color:'#02CD8D'}}>Login with Password</p>
            </div>
      </div>
      ) : (
      <>
      <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center' ,width:'100%',maxWidth:'446px'}}>
          <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
          <Select options={formValues.countryOptions} onChange={handleCountryChange} value={formValues.countryOptions.find(option => option.value === formValues.country_code)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={formValues.isLoading} styles={customStyles}/>
          </div>
          <div style={{ height: '30px', width: '2px', backgroundColor: '#ccc', margin: '0 8px', position: 'absolute', left: `${selwidth}px`, top: '50%', transform: 'translateY(-50%)' }}></div>
          <p style={{ position: 'absolute', left:'78px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{formValues.dial_code}</p>
          <input type="text" className={`form-control logininputsign ${formValues.username ? 'logininputsign-active' : ''}`} id="mobile" ref={mobileInputRef} style={{ paddingLeft: `${selwidth + 18}px`, width: '100%' }} placeholder="Mobile Number" value={formValues.username} name="mobile" onPaste={(e) => handlePaste(e)} onChange={handleUsernameChange}/>
          </div>
      </>
      )}
     {error.usernameerror && (<div className='errors'>{error.usernameerror}</div>)}
     {error422 && (<div className='errors'>{error422}</div>)}
     <button className="loginsubmit mt-4" style={{backgroundColor:islogged ? '#F77F00' : '#EAEAEA',color:islogged ? 'white' : 'hsl(0, 0%, 20%)',cursor:islogged ? 'pointer' : 'not-allowed'}} disabled={!islogged} onClick={sendotp}>Send OTP</button>
    </>
    ) : (
     <>
      {!showCountryInput ? (
      <div className="logininputboxcenter"> 
            <input className={`form-control logininputsign mt-4 ${formValues.username ? 'logininputsign-active' : ''}`} value={formValues.username} ref={inputRef} onChange={handleUsernameChange} onKeyPress={handleKeyPress} placeholder="Email or Mobile" type="text" readOnly></input>
            <div style={{ position: 'absolute', right: '30px', top: '66%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
             <img src={editimage} alt='editimage' className='editimage1' onClick={logwithootp}></img> 
            {/* <p onClick={toggleLoginForm} style={{ fontSize: '10px',marginBottom:'0px', fontWeight: "500", color:'#02CD8D'}}>Login with Password</p> */}
            </div>
      </div>
      ) : (
      <>
      <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center' ,width:'100%',maxWidth:'446px'}}>
          <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
          <Select isDisabled={true} options={formValues.countryOptions} onChange={handleCountryChange} value={formValues.countryOptions.find(option => option.value === formValues.country_code)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={formValues.isLoading} styles={customStyles}/>
          </div>
          <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
             <img src={editimage} alt='editimage' className='editimage1' onClick={logwithootp}></img> 
            {/* <p onClick={toggleLoginForm} style={{ fontSize: '10px',marginBottom:'0px', fontWeight: "500", color:'#02CD8D'}}>Login with Password</p> */}
            </div>
          <div style={{ height: '30px', width: '2px', backgroundColor: '#ccc', margin: '0 8px', position: 'absolute', left: `${selwidth}px`, top: '50%', transform: 'translateY(-50%)' }}></div>
          <p style={{ position: 'absolute', left:'78px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{formValues.dial_code}</p>
          <input type="text" className={`form-control logininputsign ${formValues.username ? 'logininputsign-active' : ''}`} id="mobile" ref={mobileInputRef} style={{ paddingLeft: `${selwidth + 18}px`, width: '100%' }} placeholder="Mobile Number" value={formValues.username} name="mobile" onPaste={(e) => handlePaste(e)} onChange={handleUsernameChange} readOnly/>
          </div>
      </>
      )}
      <input type="text" value={formValues.otp} onChange={handleChange} onKeyDown={handleKeyDown}  maxLength={6} className={`form-control mt-3 logininputsign ${formValues.otp ? 'logininputsign-active' : ''}`} name='otp'  id="inputField" placeholder="Enter OTP" />
      {error422 && (<div className='errors'>{error422}</div>)}
     <button className="loginsubmit mt-4" style={{backgroundColor:islogged1 ? '#F77F00' : '#EAEAEA',color:islogged1 ? 'white' : 'hsl(0, 0%, 20%)',cursor:islogged1 ? 'pointer' : 'not-allowed'}} disabled={!islogged1} onClick={login}>Confirm</button>
     </> 
    )}
    </>
  )
})

export default WithToast(Loginwithotp);